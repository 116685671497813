import { render, staticRenderFns } from "./dv-right-program.vue?vue&type=template&id=9c9b0164&"
import script from "./dv-right-program.vue?vue&type=script&lang=js&"
export * from "./dv-right-program.vue?vue&type=script&lang=js&"
import style0 from "./dv-right-program.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports