import * as $ from 'jquery'
import dataV from './dataV'

const px = 1100,
	during = 800

const panels = () => {
	return [
		window.TopHeaderComponent,

		window.LeftScreenComponent,
		window.RightScreenComponent,
		window.BottomScreenComponent,
		
		
		window.LeftIndexComponent,
		window.LeftProgramComponent,
		window.LeftTunnelComponent,

		window.RightIndexComponent,
		window.RightProgramComponent,

		window.CenterMapComponent,
		window.CenterListComponent,

		window.BottomIndexComponent,
		window.BottomProgramComponent,
		window.BottomTunnelComponent,
	]
}

const hasOpenPanel = () => {
	let has = false
	panels().forEach(panel => {
		if (panel) {
			if (panel.Id.toLowerCase().indexOf('left') === 0) {
				if ($(panel.$el).offset().left === 0) has = true
			} else if (panel.Id.toLowerCase().indexOf('right') === 0) {
				if ($(panel.$el).offset().left < (3840 - px / 2)) has = true
			}
		}
	})
	return has
}

const Page = {
	CurrentId: '',
	CurrentTunnelId: '',
	CurrentLevel: 0,
	CurrentSectionId:'',
	LastLevel:'',
	LastId:'',
	testingOrgId: '',

	Toggle: (Vue, level = 0, Id = '', lastlevel) => {
		// console.log('level', level)
		Page.CurrentLevel = level
		Page.LastLevel = lastlevel
		Page.LastId = Id
		if (level === 1) Page.CurrentSectionId = Id
		if (level === 2) Page.CurrentId = Id
		if (level === 3) Page.CurrentTunnelId = Id

		dataV.ModelWindows.close()

		// console.log('CurrentLevel：', Page.CurrentLevel)

		switch (level) {
			case 0:
				Vue.$api.request({
					Vue,
					apiName: 'screen'
				}).then(response => {
					console.log(109,JSON.parse(response.data))
					window.ApiData['screen'] = (() => {
						try {
							return JSON.parse(response.data)
						} catch (e) {
							return response.data
						}
					})()
					if (response.state === 'success') {
						togglePanel([window.LeftScreenComponent.Id, window.RightScreenComponent.Id, window.BottomScreenComponent.Id, window.CenterMapComponent.Id],function(){
							
								updateComponetData(window.CenterMapComponent, JSON.parse(response.data))
								updateComponetData(window.LeftScreenComponent, JSON.parse(response.data))
								updateComponetData(window.RightScreenComponent, JSON.parse(response.data))
								// console.log('JSON.parse(response.data)', JSON.parse(response.data))
								updateComponetData(window.BottomScreenComponent, JSON.parse(response.data))
						})
					} else {
						console.log('获取数据失败：', response.message)
					}
				})
				window.RightScreenComponent.selectedTestingOrg = dataV.Page.testingOrgId
				
				window.BottomScreenComponent.selectedTestingOrg = dataV.Page.testingOrgId
				break
			case 1:
				Vue.$api.request({
					Vue,
					apiName: 'index',
					query: {
						bidSectionId: Id
					}
				}).then(response => {
					console.log(110,JSON.parse(response.data))
					window.ApiData[Id] = (() => {
						try {
							return JSON.parse(response.data)
						} catch (e) {
							return response.data
						}
					})()
					if (response.state === 'success') {
						
						loadSection(window.ApiData[Id])
					} else {
						console.log('获取标段数据失败：', response.message)
						alert('获取标段数据失败：' + response.message)
					}
				})
				window.RightIndexComponent.selectedTestingOrg = dataV.Page.testingOrgId
				window.RightIndexComponent.currentPageLevel = dataV.Page.CurrentLevel
				
				break
				// togglePanel([window.LeftIndexComponent.Id, window.RightIndexComponent.Id, window.BottomIndexComponent.Id, window.CenterMapComponent.Id, window.CenterListComponent.Id])
				// break
			case 2:
				Vue.$api.request({
					Vue,
					apiName: 'program',
					query: {
						projectInfoId: Id
					}
				}).then(response => {
					//console.log(111,JSON.parse(response.data))
					window.ApiData[Id] = (() => {
						try {
							return JSON.parse(response.data)
						} catch (e) {
							return response.data
						}
					})()
					if (response.state === 'success') {
						
						loadProgram(window.ApiData[Id])
					} else {
						console.log('获取项目数据失败：', response.message)
						alert('获取项目数据失败：' + response.message)
					}
				})
				window.RightProgramComponent.selectedTestingOrg = dataV.Page.testingOrgId
				window.BottomProgramComponent.currentPageLevel = dataV.Page.CurrentLevel
				break
			case 3:
				Vue.$api.request({
					Vue,
					apiName: 'tunnel',
					query: {
						tunnelInfoId: Id
					}
				}).then(response => {
					window.ApiData[Id] = (() => {
						try {
							return JSON.parse(response.data)
						} catch (e) {
							return response.data
						}
					})()
					if (response.state === 'success') {
						loadTunnel(window.ApiData[Id])
					} else {
						console.log('获取隧道数据失败：', response.message)
						alert('获取隧道数据失败：' + response.message)
					}
				})
				window.CenterMapComponent.selectedTestingOrg = dataV.Page.testingOrgId
				break
		}
	}
}
const loadSection = (data) => {
	// console.log(150,data)
	togglePanel([window.LeftIndexComponent.Id,window.RightIndexComponent.Id,window.BottomIndexComponent.Id,window.CenterMapComponent.Id,], function () {
		updateComponetData(window.LeftIndexComponent, data)
		updateComponetData(window.RightIndexComponent, data)
		updateComponetData(window.BottomIndexComponent, data)
		updateComponetData(window.CenterMapComponent, data)
		window.BottomIndexComponent.selectedTestingOrg = dataV.Page.testingOrgId
		window.CenterListComponent.ProjectList = data.ProjectCompareFault.map(item => {
			return { Id: item.Id, Name: item.Name }
		})
		// window.CenterListComponent.TunnelList = []	// 此处需要完善接口
	})
	// togglePanel([window.LeftIndexComponent.Id, window.RightIndexComponent.Id, window.CenterMapComponent.Id, window.CenterListComponent.Id, window.BottomIndexComponent.Id], function () {
	// 	updateComponetData(window.LeftProgramComponent, data)
	// 	updateComponetData(window.RightProgramComponent, data)
	// 	updateComponetData(window.BottomProgramComponent, data)
	// })
}

const loadProgram = (data) => {
	// console.log(169,data)
	togglePanel([window.LeftProgramComponent.Id,window.RightProgramComponent.Id,window.CenterMapComponent.Id,window.BottomProgramComponent.Id,window.CenterListComponent.Id], function () {
		updateComponetData(window.LeftProgramComponent, data)
		updateComponetData(window.RightProgramComponent, data)
		updateComponetData(window.BottomProgramComponent, data)
		updateComponetData(window.CenterMapComponent, data)
		window.BottomProgramComponent.selectedTestingOrg = dataV.Page.testingOrgId
		window.CenterListComponent.TunnelList = data.TunnelCompareFault.map(item => {
			return { Id: item.Id, Name: item.Name }
		})
	})
}

const loadTunnel = (data) => {
	togglePanel([window.LeftTunnelComponent.Id, window.CenterMapComponent.Id, window.BottomTunnelComponent.Id], function () {
		updateComponetData(window.LeftTunnelComponent, data)
		updateComponetData(window.BottomTunnelComponent, data)
	})
}

const updateComponetData = (VueEl, data) => {
	// console.log('test: ', test)
	Object.keys(data).forEach(key => {
		// console.log(VueEl.Id, key)
		VueEl[key] = data[key]
	})
}

const syncPanels = () => {
	if (window.LeftScreenComponent) window.LeftScreenComponent.sync()
	if (window.RightScreenComponent) window.RightScreenComponent.sync()
	if (window.BottomScreenComponent) window.BottomScreenComponent.sync()
	if (window.BottomIndexComponent) window.BottomIndexComponent.sync()
	if (window.BottomProgramComponent) window.BottomProgramComponent.sync()
	if (window.RightIndexComponent) window.RightIndexComponent.sync()
	if (window.LeftIndexComponent) window.LeftIndexComponent.sync()
	if (window.CenterMapComponent) window.CenterMapComponent.sync()
	if (window.CenterListComponent) window.CenterListComponent.sync()
	if (window.BottomTunnelComponent) window.BottomTunnelComponent.sync()
	if (window.RightProgramComponent) window.RightProgramComponent.sync()
}

const togglePanel = (showIds, dataUpdateFun) => {
	// 检测 所有面板状态
	const needDelay = hasOpenPanel()
	if (needDelay) {
		dataV.Sound.play('滑出')
		setTimeout(function () {
			dataV.Sound.play('滑入')
		}, during)
	}
	// 更新各面板 数据 和 状态
	setTimeout(function () {
		syncPanels()
		if (dataUpdateFun) dataUpdateFun()
	}, during)

	const fullShowIds = showIds.concat([window.TopHeaderComponent.Id])

	panels().forEach(panel => {
		if (panel) {
			var direct = ''
			if (panel.Id.toLowerCase().indexOf('left') === 0) direct = 'left'
			if (panel.Id.toLowerCase().indexOf('right') === 0) direct = 'right'
			if (panel.Id.toLowerCase().indexOf('top') === 0) direct = 'top'
			if (panel.Id.toLowerCase().indexOf('bottom') === 0) direct = 'bottom'
			if (panel.Id.toLowerCase().indexOf('center') === 0) direct = 'center'

			panelAnimate(panel, direct, 'close')

			// 打开需要的面板
			if (fullShowIds.indexOf(panel.Id) > -1) setTimeout(() => panelAnimate(panel, direct, 'open'), needDelay ? during + 300 : 0)
		}
	})
}

const panelAnimate = (VueEl, direct, key = 'close') => { // key = close || open
	let targetPX = 0,
		opacity = 1;
	switch (direct) {
		case 'left':
			targetPX = key === 'open' ? 0 : -1100
			opacity = null
			break
		case 'right':
			targetPX = key === 'open' ? 0 : -1100
			opacity = null
			break
		case 'top':
			targetPX = key === 'open' ? 0 : -150
			opacity = null
			break
		case 'bottom':
			targetPX = key === 'open' ? 40 : -800
			opacity = null
			break
		case 'center':
			targetPX = null
			opacity = key === 'open' ? 1 : 0
			break
	}

	// console.log('here', VueEl.Id.toLowerCase(), direct, targetPX)
	const targetAnimate = () => {
		const x = targetPX != null ? {
			[direct]: targetPX + 'px'
		} : {}
		const o = opacity != null ? {
			opacity: opacity
		} : {}
		return Object.assign(x, o)
	}

	const animateCss = targetAnimate()
	// console.log(VueEl.$el, animateCss)
	$(VueEl.$el).animate(animateCss, during)
}

// Object.defineProperties(Page, {
// 	CurrentLevel: {
// 		get: function () {
// 			const value = this._CurrentLevel ? parseInt(this._CurrentLevel) : 1
// 			console.log('Get CurrentLevel：', value)
// 			return value
// 		},
// 		set: function (value) {
// 			this._CurrentLevel = value
// 			console.log('Set CurrentLevel：', value)

// 			this.togglePage()
// 		}
// 	}
// })

if (!window.Page) window.Page = Page;

export default Page