<style >
#dv-center-list {
  position: absolute;
  left: 1250px;
  top: 570px;
  width: 203px;
  background: url("../../../public/imgs/index/右侧浮动边框.png") left top
    no-repeat;
  opacity: 0;
}
#dv-center-list .title {
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 18px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding-left: 10px;
}
#dv-center-list .title span {
  color: #45edff;
  /* font-family: 'Work Sans', sans-serif; */
  /* font-weight: bold; */
  font-size: 18px;
}
#dv-center-list .btns {
  position: absolute;
  left: 0;
  top: 55px;
  width: 300px;
}
#dv-center-list .btns img {
  position: absolute;
  left: 8px;
  top: 0;
  width: 90px;
  height: 28px;
  cursor: pointer;
}
#dv-center-list .btns img:nth-child(2) {
  left: 104px;
}
#dv-center-list .btns div {
  position: absolute;
  left: 8px;
  top: 0;
  width: 90px;
  color: #87bafd;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  pointer-events: none;
}
#dv-center-list .btns .btn02 {
  left: 104px;
}

#dv-center-list .list {
  position: absolute;
  left: 0;
  top: 95px;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  width: 160px;
}
#dv-center-list .list-Item {
  margin-bottom: 5px;
  background: url("../../../public/imgs/icon.png") left 4px no-repeat;
  background-size: 13px;
  padding-left: 20px;
}
#dv-center-list i {
  font-style: normal;
  cursor: pointer;
}
#dv-center-list i.selected {
  color: #00fff8;
}
#dv-center-list .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: url("../../../public/imgs/index/右侧浮动边框.png") left bottom
    no-repeat;
}
</style>

<template>
  <div
    id="dv-center-list"
    :style="
      (currentPageLevel == 3 ? 'display: none' : '') +
      '; height: ' +
      (maxHeight * 27 + 105) +
      'px; z-index: 5'
    "
  >
    <div v-if="currentPageLevel === 2 && userroot < currentPageLevel">
      <div class="title">
        <i>分部列表</i>
        <span
          v-if="ProjectList.length > 0"
          v-text="'（' + ProjectList.length + '）'"
        ></span>
        <span
          v-show="toplist"
          style="
            float: right;
            margin-right: 20px;
            font-size: 12px;
            font-weight: normal;
            margin-top: 5px;
            cursor: pointer;
          "
          @click="selected(currentPageLevel - 1, CurrentSectionId)"
          >[ 返回 ]</span
        >
      </div>
      <div class="btns">
        <dv-img
          @click="ComparePrograms(ProjectList.map((item) => item.Id).join(','))"
          src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
        ></dv-img>
        <dv-img
          @click="CompareTunnels(TunnelList.map((item) => item.Id).join(','))"
          src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
        ></dv-img>

        <div class="btn01">分部对比</div>
        <div class="btn02">隧道对比</div>
      </div>
      <div class="list" :style="'height: ' + maxHeight * 27 + 'px'">
        <vue-scroll style="padding-left: 20px; wdith: 180px">
          <div
            class="list-Item"
            v-for="(item, index) in ProjectList"
            :key="index"
          >
            <i
              @click="selected(2, item.Id)"
              :class="currentProgram == item.Id ? 'selected' : ''"
              v-text="item.Name"
            ></i>
          </div>
        </vue-scroll>
      </div>
      <div class="bottom"></div>
    </div>
    <div v-if="currentPageLevel === 2 && userroot == currentPageLevel">
      <div class="title">
        <i>隧道列表</i>
        <span
          v-if="TunnelList.length > 0"
          v-text="'（' + TunnelList.length + '）'"
        ></span>
        <span
          v-show="toplist"
          style="
            float: right;
            margin-right: 20px;
            font-size: 12px;
            font-weight: normal;
            margin-top: 5px;
            cursor: pointer;
          "
          @click="selected(currentPageLevel - 1, CurrentSectionId)"
          >[ 返回 ]</span
        >
      </div>
      <div class="btns">
        <dv-img
          @click="CompareTunnels(TunnelList.map((item) => item.Id).join(','))"
          src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"
        ></dv-img>

        <div class="btn01">隧道对比</div>
      </div>
      <div class="list" :style="'height: ' + maxHeight * 27 + 'px'">
        <vue-scroll style="padding-left: 20px; wdith: 180px">
          <div
            class="list-Item"
            v-for="(item, index) in TunnelList"
            :key="index"
          >
            <i
              @click="selected(3, item.Id)"
              :class="currentProgram == item.Id ? 'selected' : ''"
              v-text="item.Name"
            ></i>
          </div>
        </vue-scroll>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import dataV from "../../bin/dataV";
import dvImg from "./dv-img";

export default {
  data: function () {
    return {
      Id: "CenterListComponent",
      toplist: false,
      BidSectionList: window.ApiData.screen.BidSectionList,
      ProjectList: [],
      TunnelList: [],

      currentPageLevel: dataV.Config.initPage.level, // 大屏当前页面层级，1-全国、2-项目部
      currentArea: dataV.Config.AreaName,

      currentProgram: dataV.Page.CurrentId,
      userroot: window.ApiData.userRoot.PageRoot - 1,
      maxListItems: 4,
    };
  },
  props: ["title"],
  created() {
    window[this.Id] = this;
  },
  methods: {
    CompareSections(all) {
      this.$api
        .request({
          Vue: this,
          apiName: "sectionContrast",
          query: { bidSectionIds: all || "all" },
        })
        .then((response) => {
          if (response.state === "success") {
            const json = JSON.parse(response.data);
            window.CenterWindowsComponent.set("sections", json);
          } else {
            alert(response.message);
          }
        });
    },
    ComparePrograms(all) {
      if (all === "") {
        window.CenterWindowsComponent.set("programs", []);
      } else {
        this.$api
          .request({
            Vue: this,
            apiName: "programContrast",
            query: { projectInfoIds: all || "all" },
          })
          .then((response) => {
            if (response.state === "success") {
              const json = JSON.parse(response.data);
              window.CenterWindowsComponent.set("programs", json);
            } else {
              alert(response.message);
            }
          });
      }
    },
    CompareTunnels(all) {
      if (all === "") {
        window.CenterWindowsComponent.set("tunnels", []);
      } else {
        this.$api
          .request({
            Vue: this,
            apiName: "tunnelContrast",
            query: { tunnelInfoIds: all || "all" },
          })
          .then((response) => {
            if (response.state === "success") {
              const json = JSON.parse(response.data);
              window.CenterWindowsComponent.set("tunnels", json);
            } else {
              alert(response.message);
            }
          });
      }
    },

    sync() {
      this.currentPageLevel = dataV.Page.CurrentLevel;
      this.CurrentSectionId = dataV.Page.CurrentSectionId;
      this.currentProgram = dataV.Page.CurrentId;
    },

    selectedBidSection(level, id) {
      dataV.Page.Toggle(this, level, id);
    },

    selected(level, id) {
      dataV.Page.CurrentId = "";
      dataV.Page.Toggle(this, level, id);
    },
  },
  watch: {
    currentPageLevel() {
      if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
        this.toplist = true;
      } else {
        this.toplist = false;
      }
    },
    "$store.state.webSocketCommand"(newval, olval) {
      if (newval == "0|0|2") {
        this.selected(this.currentPageLevel - 1, this.CurrentSectionId);
      } else {
        return;
      }
      console.log("dv-center-list监听websocket命令");
      console.log(newval);
      console.log(olval);
    },
  },
  computed: {
    maxHeight() {
      let rowCount = 0,
        rowIndex = 0;
      try {
        const list =
          this.currentPageLevel === 2 && this.userroot < this.currentPageLevel
            ? this.ProjectList
            : this.TunnelList;
        list.forEach((item) => {
          // item.Name = item.Name + item.Name
          rowCount +=
            item.Name.length > 10 ? (item.Name.length > 20 ? 3 : 2) : 1;
          rowIndex++;
          if (rowIndex >= this.maxListItems) throw new Error("exit forEach");
        });
      } catch (e) {
        e;
      }
      return rowCount > this.maxListItems ? this.maxListItems : rowCount;
    },
  },
  mounted: function () {
    // 加载api数据

    window.CenterListComponent = this;
    if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
      $(document).ready(function () {
        $("#dv-center-list .btns .btn02").css({ left: "20px" });
        $("#dv-center-list .btns .btn03").css({ left: "130px" });
      });
    }
  },
  components: {
    "dv-img": dvImg,
  },
};
</script>
