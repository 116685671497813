import dataV from '../bin/dataV'

const apiBaseUrl = dataV.Config.baseUrl.api;

const apiUrls = {
    token: '/App/GetTkyAccount',
    screen: '/App/GetScreenIndexData',
    index: '/App/GetScreenBidSectionData',
    program: '/App/GetScreenProjectData',                   // projectInfoId
    tunnel: '/App/GetScreenTunnelData',                     // tunnelInfoId
    sectionContrast: '/App/GetScreenBidSectionCompareData', // bidSectionIds（项目编号，用英文逗号隔开）
    programContrast: '/App/GetScreenProjectCompareData',    // projectInfoIds（项目编号，用英文逗号隔开）
    tunnelContrast: '/App/GetScreenTunnelCompareData',      // tunnelInfoIds（隧道编号，用英文逗号隔开，全部隧道对比传递all）
    checkOver: '/App/GetScreenCheckOverData',               // tempId（数据编号）、datatype（1：项目、2：隧道）
    fixOver: '/App/GetScreenFixOverData',                   // tempId（数据编号）、datatype（1：项目、2：隧道）
    userRoot: '/App/GetUserIndexPageRoot'                   // 用户默认打开页面信息
}

const Query = require('querystring');

let api = {
    install: function(Vue){
        Vue.prototype.$api = this;
    },
    request: ({ apiName, type, query, data, options, Vue }) => {
        query = Object.assign(query || {}, { testingOrgId: dataV.Page.testingOrgId })
        const apiUrl = apiBaseUrl + apiUrls[apiName] + (query ? '?' + (typeof(query) == 'object' ? Query.stringify(query) : query) : '');
        type = type || 'get';
        console.log(type, apiUrl);
        return type === 'post' ? Vue.$http.post(apiUrl, data, options) : Vue.$http.get(apiUrl, data, options)
    }
}

export default api