<style>
  #dv-wins { position: absolute; left: 0; top: 0; pointer-events: none; z-index: 100000000000; }

  #win-sections { position: absolute; left: 1117px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-sections .texts { position: absolute; left: 3px; top: 0; }
  #win-sections .texts .caption { float: left; width: 100%; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-sections .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-sections .texts .titles { margin-top: 12px; }
  #win-sections .texts table { float: left; width: 1300px; text-align: center; }
  #win-sections .texts table th {font-size: 14px; line-height: 44px; }
  #win-sections .texts table tr td:nth-child(1) , #win-sections .texts table th:nth-child(1) { width: 40px; }
  #win-sections .texts table tr td:nth-child(3) , #win-sections .texts table th:nth-child(3),
  #win-sections .texts table tr td:nth-child(4) , #win-sections .texts table th:nth-child(4),
  #win-sections .texts table tr td:nth-child(5) , #win-sections .texts table th:nth-child(5),
  #win-sections .texts table tr td:nth-child(6) , #win-sections .texts table th:nth-child(6),
  #win-sections .texts table tr td:nth-child(7) , #win-sections .texts table th:nth-child(7),
  #win-sections .texts table tr td:nth-child(8) , #win-sections .texts table th:nth-child(8),
  #win-sections .texts table tr td:nth-child(9) , #win-sections .texts table th:nth-child(9) { width: 105px; }
  #win-sections .texts table tr td:nth-child(10), #win-sections .texts table th:nth-child(10),
  #win-sections .texts table tr td:nth-child(11), #win-sections .texts table th:nth-child(11),
  #win-sections .texts table tr td:nth-child(12), #win-sections .texts table th:nth-child(12),
  #win-sections .texts table tr td:nth-child(13), #win-sections .texts table th:nth-child(13) { width: 120px; }
  #win-sections .texts table tr td:nth-child(10), #win-sections .texts table th:nth-child(10) ,  
  #win-sections .texts table tr td:nth-child(11), #win-sections .texts table th:nth-child(11), 
  #win-sections .texts table tr td:nth-child(12), #win-sections .texts table th:nth-child(12), 
  #win-sections .texts table tr td:nth-child(13), #win-sections .texts table th:nth-child(13) { padding: 0 10px }
  #win-sections .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-sections .texts .DataList { width: 1300px; height: 645px; }

  #win-programs { position: absolute; left: 1117px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-programs .texts { position: absolute; left: 3px; top: 0; }
  #win-programs .texts .caption { float: left; width: 100%; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-programs .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-programs .texts .titles { margin-top: 12px; }
  #win-programs .texts table { float: left; width: 1300px; text-align: center; }
  #win-programs .texts table th {font-size: 14px; line-height: 44px; }
  #win-programs .texts table tr td:nth-child(1) , #win-programs .texts table th:nth-child(1) { width: 40px; }
  #win-programs .texts table tr td:nth-child(3) , #win-programs .texts table th:nth-child(3),
  #win-programs .texts table tr td:nth-child(4) , #win-programs .texts table th:nth-child(4),
  #win-programs .texts table tr td:nth-child(5) , #win-programs .texts table th:nth-child(5),
  #win-programs .texts table tr td:nth-child(6) , #win-programs .texts table th:nth-child(6),
  #win-programs .texts table tr td:nth-child(7) , #win-programs .texts table th:nth-child(7),
  #win-programs .texts table tr td:nth-child(8) , #win-programs .texts table th:nth-child(8),
  #win-programs .texts table tr td:nth-child(9) , #win-programs .texts table th:nth-child(9) { width: 105px; }
  #win-programs .texts table tr td:nth-child(10), #win-programs .texts table th:nth-child(10),
  #win-programs .texts table tr td:nth-child(11), #win-programs .texts table th:nth-child(11),
  #win-programs .texts table tr td:nth-child(12), #win-programs .texts table th:nth-child(12),
  #win-programs .texts table tr td:nth-child(13), #win-programs .texts table th:nth-child(13) { width: 120px; }
  #win-programs .texts table tr td:nth-child(10), #win-programs .texts table th:nth-child(10) ,  
  #win-programs .texts table tr td:nth-child(11), #win-programs .texts table th:nth-child(11), 
  #win-programs .texts table tr td:nth-child(12), #win-programs .texts table th:nth-child(12), 
  #win-programs .texts table tr td:nth-child(13), #win-programs .texts table th:nth-child(13) { padding: 0 10px }
  #win-programs .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-programs .texts .DataList { width: 1300px; height: 645px; }

  #win-tunnels { position: absolute; left: 1117px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-tunnels .texts { position: absolute; left: 3px; top: 0; }
  #win-tunnels .texts .caption { float: left; width: 100%; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-tunnels .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-tunnels .texts .titles { margin-top: 12px; }
  #win-tunnels .texts table { float: left; width: 1300px; text-align: center; }
  #win-tunnels .texts table th {font-size: 14px; line-height: 44px; }
  #win-tunnels .texts table tr td:nth-child(1) , #win-tunnels .texts table th:nth-child(1) { width: 40px; }
  #win-tunnels .texts table tr td:nth-child(3) , #win-tunnels .texts table th:nth-child(3),
  #win-tunnels .texts table tr td:nth-child(4) , #win-tunnels .texts table th:nth-child(4),
  #win-tunnels .texts table tr td:nth-child(5) , #win-tunnels .texts table th:nth-child(5),
  #win-tunnels .texts table tr td:nth-child(6) , #win-tunnels .texts table th:nth-child(6),
  #win-tunnels .texts table tr td:nth-child(7) , #win-tunnels .texts table th:nth-child(7),
  #win-tunnels .texts table tr td:nth-child(8) , #win-tunnels .texts table th:nth-child(8),
  #win-tunnels .texts table tr td:nth-child(9) , #win-tunnels .texts table th:nth-child(9) { width: 105px; }
  #win-tunnels .texts table tr td:nth-child(10), #win-tunnels .texts table th:nth-child(10),
  #win-tunnels .texts table tr td:nth-child(11), #win-tunnels .texts table th:nth-child(11),
  #win-tunnels .texts table tr td:nth-child(12), #win-tunnels .texts table th:nth-child(12),
  #win-tunnels .texts table tr td:nth-child(13), #win-tunnels .texts table th:nth-child(13) { width: 120px; }
  #win-tunnels .texts table tr td:nth-child(10), #win-tunnels .texts table th:nth-child(10) ,  
  #win-tunnels .texts table tr td:nth-child(11), #win-tunnels .texts table th:nth-child(11), 
  #win-tunnels .texts table tr td:nth-child(12), #win-tunnels .texts table th:nth-child(12), 
  #win-tunnels .texts table tr td:nth-child(13), #win-tunnels .texts table th:nth-child(13) { padding: 0 10px }
  #win-tunnels .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-tunnels .texts .DataList { width: 1300px; height: 645px; }

  #win-sevens { position: absolute; left: 1346px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-sevens .texts { position: absolute; left: 3px; top: 0; }
  #win-sevens .texts .caption { float: left; width: 100%; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-sevens .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-sevens .texts .titles { margin-top: 12px; }
  #win-sevens .texts table { float: left; width: 1142px; text-align: center; }
  #win-sevens .texts table th {font-size: 14px; line-height: 44px; }
  #win-sevens .texts table tr td:nth-child(1) , #win-sevens .texts table th:nth-child(1) { width: 10px; }
  #win-sevens .texts table tr td:nth-child(3) , #win-sevens .texts table th:nth-child(3) { width: 200px; }
  #win-sevens .texts table tr td:nth-child(4) , #win-sevens .texts table th:nth-child(4),
  #win-sevens .texts table tr td:nth-child(5) , #win-sevens .texts table th:nth-child(5),
  #win-sevens .texts table tr td:nth-child(6) , #win-sevens .texts table th:nth-child(6),
  #win-sevens .texts table tr td:nth-child(7) , #win-sevens .texts table th:nth-child(7),
  #win-sevens .texts table tr td:nth-child(8) , #win-sevens .texts table th:nth-child(8) { width: 100px; }
  #win-sevens .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-sevens .texts .DataList { width: 1142px; height: 645px; }

  #win-months { position: absolute; left: 1346px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-months .texts { position: absolute; left: 3px; top: 0; }
  #win-months .texts .caption { float: left; width: 100%; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-months .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-months .texts .titles { margin-top: 12px; }
  #win-months .texts table { float: left; width: 1142px; text-align: center; }
  #win-months .texts table th {font-size: 14px; line-height: 44px; }
  #win-months .texts table tr td:nth-child(1) , #win-months .texts table th:nth-child(1) { width: 10px; }
  #win-months .texts table tr td:nth-child(3) , #win-months .texts table th:nth-child(3) { width: 200px; }
  #win-months .texts table tr td:nth-child(4) , #win-months .texts table th:nth-child(4),
  #win-months .texts table tr td:nth-child(5) , #win-months .texts table th:nth-child(5),
  #win-months .texts table tr td:nth-child(6) , #win-months .texts table th:nth-child(6),
  #win-months .texts table tr td:nth-child(7) , #win-months .texts table th:nth-child(7),
  #win-months .texts table tr td:nth-child(8) , #win-months .texts table th:nth-child(8) { width: 100px; }
  #win-months .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-months .texts .DataList { width: 1142px; height: 645px; }

  .sort { cursor: pointer; }
  .sort i { color: gray; }
  .sort i.current { color: red; }

  #win-imgvideos { position: absolute; left: 1346px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-imgvideos .texts { position: absolute; left: 0; top: 0; }
  #win-imgvideos .texts .caption { float: left; width: 1142px; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-imgvideos .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-imgvideos .imgvideos { position: absolute; left: 3px; top: 70px; width: 1142px; height: 700px; background-color: black; }
  #win-imgvideos .imgvideos img { width: 1142px; height: 700px; }
  #win-imgvideos .swiperBtns { position: absolute; left: 3px; top: 70px; width: 1142px; height: 700px; pointer-events: none; }
  #win-imgvideos .swiperBtns .leftBtn { position: absolute; left: 20px; top: 334px; width: 32px; height: 32px; background-color: rgb(0, 0, 0); border: 1px solid rgb(212, 212, 212); border-radius: 32px; opacity: 0.5; text-align: center; line-height: 32px; overflow: hidden; font-size: 24px; cursor: pointer; pointer-events: all; }
  #win-imgvideos .swiperBtns .rightBtn { position: absolute; right: 20px; top: 334px; width: 32px; height: 32px; background-color: rgb(0, 0, 0); border: 1px solid rgb(212, 212, 212); border-radius: 32px; opacity: 0.5; text-align: center; line-height: 32px; overflow: hidden; font-size: 24px; cursor: pointer; pointer-events: all; }

  #win-operation { position: absolute; left: 1346px; top: 170px; pointer-events: all; overflow: hidden; display: none; }
  #win-operation .texts { position: absolute; left: 0; top: 0; }
  #win-operation .texts .caption { float: left; width: 1142px; line-height: 150%; font-size: 18px; font-weight: bold; margin-top: 30px; padding-left: 10px; }
  #win-operation .btnClose { float: right; margin: -28px 10px -6px 0; width: 32px; cursor: pointer; }
  #win-operation .texts .titles { margin-top: 12px; }
  #win-operation .texts table { float: left; width: 1142px; text-align: center; }
  #win-operation .texts table th {font-size: 14px; line-height: 44px; }
  #win-operation .texts table tr td:nth-child(1) , #win-operation .texts table th:nth-child(1) { width: 10px; }
  #win-operation .texts table tr td:nth-child(2) , #win-operation .texts table th:nth-child(2) { width: 200px; }
  #win-operation .texts table td {font-size: 16px; height: 30px; background-color: #041A41; color: #2AE1E8; }
  #win-operation .texts .DataList { width: 1142px; height: 645px; }
</style>

<template>
  <div id="dv-wins" :style="'width: ' + width + 'px; height: ' + height + 'px;'">
    <div id="win-operation">
      <dv-img src="/imgs/p101/01-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">操作手册</div>
          <dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('operation')"></dv-img>
          <table class="titles" >
          <tr>
            <th></th>
            <th class="sort" >按键</th>
            <th class="sort" >说明</th>
          </tr>
        </table>
        <div class="DataList">
            <table >
              <tr><td>W（↑）</td><td>向前走动</td></tr>
              <tr><td>A（←）</td><td>向前左动</td></tr>
              <tr><td>S（↓）</td><td>向前后动</td></tr>
              <tr><td>D（→）</td><td>向前右动</td></tr>
              <tr><td>I</td><td>视野向上看</td></tr>
              <tr><td>J</td><td>视野向左看</td></tr>
              <tr><td>K</td><td>视野向下看</td></tr>
              <tr><td>L</td><td>视野向右看</td></tr>
              <tr><td>V</td><td>是否打开全屏</td></tr>
              <tr><td> <dv-img :src="'imgs/p4/95d3ab63-9402-43c5-91be-18a9606a96bb.png'"></dv-img></td><td>衬砌厚度不足</td></tr>
              <tr><td> <dv-img :src="'imgs/p4/37cbb04f-61d9-4de9-a3ec-635c055d8153.png'"></dv-img></td><td>钢筋缺失问题</td></tr>

              <tr><td> <dv-img :src="'imgs/p4/89330cac-5598-4f6a-a953-492dac43bb58.png'"></dv-img></td><td>其他问题</td></tr>

              <tr><td> <dv-img :src="'imgs/p4/bc4487d1-ddc3-420c-847b-3b0dd40ba078.png'"></dv-img></td><td>防裂钢筋网问题</td></tr>

              <!-- <tr><td> <dv-img :src="'imgs/p4/bca4d53f0d60ebb32c5b98086a22ded.png'"></dv-img></td><td>是否打开全屏</td></tr> -->

            </table>
        </div>
      </div>
      </div>
    
    <div id="win-sections">
      <dv-img src="/imgs/p101/01-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">标段对比（{{sections.length}}）</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('sections')"></dv-img>
        <table class="titles" cellspacing = "2">
          <tr>
            <th></th>
            <th class="sort" @click="sort('sections', 'Name')">标段名称 <span v-if="sorts['sections']['Name']"><i :class="sorts['sections']['Name'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['Name'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'TestRate')">检测覆盖率 <span v-if="sorts['sections']['TestRate']"><i :class="sorts['sections']['TestRate'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['TestRate'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataCountFault')">缺陷数量 <span v-if="sorts['sections']['DataCountFault']"><i :class="sorts['sections']['DataCountFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataCountFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataCountFix')">修复量 <span v-if="sorts['sections']['DataCountFix']"><i :class="sorts['sections']['DataCountFix'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataCountFix'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataCountFaultBad')">重大缺陷数量 <span v-if="sorts['sections']['DataCountFaultBad']"><i :class="sorts['sections']['DataCountFaultBad'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataCountFaultBad'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataRateFault')">总缺陷率 <span v-if="sorts['sections']['DataRateFault']"><i :class="sorts['sections']['DataRateFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataRateFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <!-- <th class="sort" @click="sort('sections', 'OverTime7Count')">7天验证超时 <span v-if="sorts['sections']['OverTime7Count']"><i :class="sorts['sections']['OverTime7Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['OverTime7Count'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'OverTime30Count')">30天闭合超时 <span v-if="sorts['sections']['OverTime30Count']"><i :class="sorts['sections']['OverTime30Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['OverTime30Count'] == 'desc' ? 'current' : ''">↓</i></span></th> -->
            <th class="sort" @click="sort('sections', 'DataRate1')">厚度不足 <span v-if="sorts['sections']['DataRate1']"><i :class="sorts['sections']['DataRate1'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataRate1'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataRate2')">钢筋缺失 <span v-if="sorts['sections']['DataRate2']"><i :class="sorts['sections']['DataRate2'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataRate2'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataRate3')">防裂钢筋网问题 <span v-if="sorts['sections']['DataRate3']"><i :class="sorts['sections']['DataRate3'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataRate3'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('sections', 'DataRate4')">空洞、不密实 <span v-if="sorts['sections']['DataRate4']"><i :class="sorts['sections']['DataRate4'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['sections']['DataRate4'] == 'desc' ? 'current' : ''">↓</i></span></th>
          </tr>
        </table>
        <div class="DataList">
          <vue-scroll>
            <table cellspacing = "2">
              <tr v-for="(item, index) in sections" :key="index">
                <td style="font-family: 'Fjalla One', sans-serif; background: #041A41 url('./imgs/p101/三角和圆圈.png') no-repeat left top;"><i style="display: inline-block; width: 30px; background-color: #041A41">{{index + 1}}</i></td>
                <td>{{item.Name}}</td>
                <td>{{item.TestRate}}%</td><td>{{item.DataCountFault}}</td><td>{{item.DataCountFix}}</td><td>{{item.DataCountFaultBad}}</td><td>{{item.DataRateFault}}%</td>
                <!-- <td>{{item.OverTime7Count}}</td>
                <td>{{item.OverTime30Count}}</td> -->
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate1" valuescale="10" barcolorh="0" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate2" valuescale="10" barcolorh="30" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate3" valuescale="10" barcolorh="190" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate4" valuescale="10" barcolorh="0" barcolors="5%" during="3" showtext="true"></dv-bar-3d></div></td>
              </tr>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div id="win-programs">
      <dv-img src="/imgs/p101/01-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">分部对比（{{programs.length}}）</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('programs')"></dv-img>
        <table class="titles" cellspacing = "2">
          <tr>
            <th></th>
            <th class="sort" @click="sort('programs', 'Name')">分部名称 <span v-if="sorts['programs']['Name']"><i :class="sorts['programs']['Name'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['Name'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'TestRate')">检测覆盖率 <span v-if="sorts['programs']['TestRate']"><i :class="sorts['programs']['TestRate'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['TestRate'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataCountFault')">缺陷数量 <span v-if="sorts['programs']['DataCountFault']"><i :class="sorts['programs']['DataCountFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataCountFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataCountFix')">修复量 <span v-if="sorts['programs']['DataCountFix']"><i :class="sorts['programs']['DataCountFix'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataCountFix'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataCountFaultBad')">重大缺陷数量 <span v-if="sorts['programs']['DataCountFaultBad']"><i :class="sorts['programs']['DataCountFaultBad'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataCountFaultBad'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataRateFault')">总缺陷率 <span v-if="sorts['programs']['DataRateFault']"><i :class="sorts['programs']['DataRateFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataRateFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <!-- <th class="sort" @click="sort('programs', 'OverTime7Count')">7天验证超时 <span v-if="sorts['programs']['OverTime7Count']"><i :class="sorts['programs']['OverTime7Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['OverTime7Count'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'OverTime30Count')">30天闭合超时 <span v-if="sorts['programs']['OverTime30Count']"><i :class="sorts['programs']['OverTime30Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['OverTime30Count'] == 'desc' ? 'current' : ''">↓</i></span></th> -->
            <th class="sort" @click="sort('programs', 'DataRate1')">厚度不足 <span v-if="sorts['programs']['DataRate1']"><i :class="sorts['programs']['DataRate1'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataRate1'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataRate2')">钢筋缺失 <span v-if="sorts['programs']['DataRate2']"><i :class="sorts['programs']['DataRate2'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataRate2'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataRate3')">防裂钢筋网问题 <span v-if="sorts['programs']['DataRate3']"><i :class="sorts['programs']['DataRate3'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataRate3'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('programs', 'DataRate4')">空洞、不密实 <span v-if="sorts['programs']['DataRate4']"><i :class="sorts['programs']['DataRate4'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['programs']['DataRate4'] == 'desc' ? 'current' : ''">↓</i></span></th>
          </tr>
        </table>
        <div class="DataList">
          <vue-scroll>
            <table cellspacing = "2">
              <tr v-for="(item, index) in programs" :key="index">
                <td style="font-family: 'Fjalla One', sans-serif; background: #041A41 url('./imgs/p101/三角和圆圈.png') no-repeat left top;"><i style="display: inline-block; width: 30px; background-color: #041A41">{{index + 1}}</i></td>
                <td>{{item.Name}}</td>
                <td>{{item.TestRate}}%</td><td>{{item.DataCountFault}}</td><td>{{item.DataCountFix}}</td><td>{{item.DataCountFaultBad}}</td><td>{{item.DataRateFault}}%</td>
                <!-- <td>{{item.OverTime7Count}}</td>
                <td>{{item.OverTime30Count}}</td> -->
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate1" valuescale="10" barcolorh="0" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate2" valuescale="10" barcolorh="30" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate3" valuescale="10" barcolorh="190" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :key="item.Name" :value="item.DataRate4" valuescale="10" barcolorh="0" barcolors="5%" during="3" showtext="true"></dv-bar-3d></div></td>
              </tr>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div id="win-tunnels">
      <dv-img src="/imgs/p101/01-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">隧道对比（{{tunnels.length}}）</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('tunnels')"></dv-img>
        <table class="titles" cellspacing = "2">
          <tr>
            <th></th>
            <th class="sort" @click="sort('tunnels', 'Name')">隧道名称 <span v-if="sorts['tunnels']['Name']"><i :class="sorts['tunnels']['Name'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['Name'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'TestRate')">检测覆盖率 <span v-if="sorts['tunnels']['TestRate']"><i :class="sorts['tunnels']['TestRate'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['TestRate'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataCountFault')">缺陷数量 <span v-if="sorts['tunnels']['DataCountFault']"><i :class="sorts['tunnels']['DataCountFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataCountFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataCountFix')">修复量 <span v-if="sorts['tunnels']['DataCountFix']"><i :class="sorts['tunnels']['DataCountFix'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataCountFix'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataCountFaultBad')">重大缺陷数量 <span v-if="sorts['tunnels']['DataCountFaultBad']"><i :class="sorts['tunnels']['DataCountFaultBad'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataCountFaultBad'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataRateFault')">总缺陷率 <span v-if="sorts['tunnels']['DataRateFault']"><i :class="sorts['tunnels']['DataRateFault'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataRateFault'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <!-- <th class="sort" @click="sort('tunnels', 'OverTime7Count')">7天验证超时 <span v-if="sorts['tunnels']['OverTime7Count']"><i :class="sorts['tunnels']['OverTime7Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['OverTime7Count'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'OverTime30Count')">30天闭合超时 <span v-if="sorts['tunnels']['OverTime30Count']"><i :class="sorts['tunnels']['OverTime30Count'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['OverTime30Count'] == 'desc' ? 'current' : ''">↓</i></span></th> -->
            <th class="sort" @click="sort('tunnels', 'DataRate1')">厚度不足 <span v-if="sorts['tunnels']['DataRate1']"><i :class="sorts['tunnels']['DataRate1'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataRate1'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataRate2')">钢筋缺失 <span v-if="sorts['tunnels']['DataRate2']"><i :class="sorts['tunnels']['DataRate2'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataRate2'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataRate3')">防裂钢筋网问题 <span v-if="sorts['tunnels']['DataRate3']"><i :class="sorts['tunnels']['DataRate3'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataRate3'] == 'desc' ? 'current' : ''">↓</i></span></th>
            <th class="sort" @click="sort('tunnels', 'DataRate4')">空洞、不密实 <span v-if="sorts['tunnels']['DataRate4']"><i :class="sorts['tunnels']['DataRate4'] == 'asc' ? 'current' : ''">↑</i><i :class="sorts['tunnels']['DataRate4'] == 'desc' ? 'current' : ''">↓</i></span></th>
          </tr>
        </table>
        <div class="DataList">
          <vue-scroll>
            <table cellspacing = "2">
              <tr v-for="(item, index) in tunnels" :key="index">
                <td style="font-family: 'Fjalla One', sans-serif; background: #041A41 url('./imgs/p101/三角和圆圈.png') no-repeat left top;"><i style="display: inline-block; width: 30px; background-color: #041A41">{{index + 1}}</i></td>
                <td>{{item.Name}}</td><td>{{item.TestRate}}%</td><td>{{item.DataCountFault}}</td><td>{{item.DataCountFix}}</td><td>{{item.DataCountFaultBad}}</td><td>{{item.DataRateFault}}%</td>
                <!-- <td>{{item.OverTime7Count}}</td>
                <td>{{item.OverTime30Count}}</td> -->
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :value="item.DataRate1" valuescale="10" barcolorh="0" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :value="item.DataRate2" valuescale="10" barcolorh="30" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :value="item.DataRate3" valuescale="10" barcolorh="190" during="3" showtext="true"></dv-bar-3d></div></td>
                <td><div><dv-bar-3d v-if="hackReset" height="16px" :value="item.DataRate4" valuescale="10" barcolorh="0" barcolors="5%" during="3" showtext="true"></dv-bar-3d></div></td>
              </tr>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div id="win-sevens">
      <dv-img src="/imgs/p101/02-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">七天超时确认（处）</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('sevens')"></dv-img>
        <table class="titles" cellspacing = "2">
          <tr>
            <th></th><th>隧道信息</th><th>检测里程-位置-长度（米）</th><th>缺陷位置</th><th>实测值</th><th>设计值</th><th>缺陷状态</th><th>备注</th>
          </tr>
        </table>
        <div class="DataList">
          <vue-scroll>
            <table cellspacing = "2">
              <tr v-for="(item, index) in sevens" :key="index">
                <td style="text-align: left"></td>
                <td>{{item.Name}}</td><td>{{item.PileNumRange + '(' + item.FaultLength + ')'}}</td><td>{{item.FaultPosition}}</td><td>{{item.ActualAmount}}</td><td>{{item.DesignAmount}}</td>
                <td>{{item.Status}}</td><td>{{item.FaultSonType + ' ' + item.BackMemo }}</td>
              </tr>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div id="win-months">
      <dv-img src="/imgs/p101/02-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">30天超时确认（处）</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('months')"></dv-img>
        <table class="titles" cellspacing = "2">
          <tr>
            <th></th><th>隧道信息</th><th>检测里程-位置-长度（米）</th><th>缺陷位置</th><th>实测值</th><th>设计值</th><th>缺陷状态</th><th>备注</th>
          </tr>
        </table>
        <div class="DataList">
          <vue-scroll>
            <table cellspacing = "2">
              <tr v-for="(item, index) in months" :key="index">
                <td style="text-align: left"></td>
                <td>{{item.Name}}</td><td>{{item.PileNumRange + '(' + item.FaultLength + ')'}}</td><td>{{item.FaultPosition}}</td><td>{{item.ActualAmount}}</td><td>{{item.DesignAmount}}</td>
                <td>{{item.Status}}</td><td>{{item.FaultSonType + ' ' + item.BackMemo }}</td>
              </tr>
            </table>
          </vue-scroll>
        </div>
      </div>
    </div>

    <div id="win-imgvideos">
      <dv-img src="/imgs/p101/02-bg.png"></dv-img>
      <div class="texts">
        <div class="caption">影视频资料</div>
				<dv-img class="btnClose" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="clear('imgvideos')"></dv-img>
        <div id="swiperVideos" class="imgvideos">
          <Swiper :height="'700px'" v-model="imgvideosIndex" dots-position="center" @on-index-change="topAllVideos">
            <SwiperItem v-for="(item, index) in imgvideos" :key="index">
              <img style="width: 100%; height: 700px" :src="ImgBaseUrl + item.img" alt="" v-if="item.type == 'img'" />
              <video :src="ImgBaseUrl + item.img" width="100%" controls v-if="item.type == 'video'"></video>
            </SwiperItem>
          </Swiper>
        </div>
        <div class="swiperBtns">
          <div class="leftBtn" @click="swiperChange('-')">&lt;</div>
          <div class="rightBtn" @click="swiperChange('+')">&gt;</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery'
import * as _ from 'lodash'
import dataV from '../../bin/dataV'
import dvImg from "./dv-img.vue"
import dvBar3d from "./dv-bar-3d.vue"
import { Swiper, SwiperItem } from 'vux'

export default {
  data() {
    return {
      Id: "CenterWindowsComponent",

      width: dataV.Config.windowWidth(),
      height: dataV.Config.windowHeight(),
      ImgBaseUrl: dataV.Config.baseUrl.img,

      hackReset: true,

      during: 500,

      info: {
        sections: {w: 1306, h: 776, t: 0},
        programs: {w: 1306, h: 776, t: 0},
        tunnels: {w: 1306, h: 776, t: 0},
        sevens: {w: 1148, h: 776, t: 0},
        months: {w: 1148, h: 776, t: 0},
        imgvideos: {w: 1148, h: 776, t: 0},
        operation:{w: 1148, h: 776, t: 0}
      },

      sorts: {
        sections: {},
        programs: {},
        tunnels: {}
      },

      WindowStatus: {
        issectionsOpen: false,
        isprogramsOpen: false,
        istunnelsOpen: false,
        issevensOpen: false,
        ismonthsOpen: false,
        isimgvideosOpen: false,
        isoperationOpen: false,
      },
      sections: [],
      programs: [],
      tunnels: [],
      sevens: [],
      months: [],
      imgvideos: [],
      imgvideosIndex: 0,
    };
  },
  components: {
    'dv-img': dvImg,
    "dv-bar-3d": dvBar3d,
    Swiper,
    SwiperItem,
  },
  created() {
			window[this.Id] =  this;
  },
  methods: {
    topAllVideos(){
      $.each($('#swiperVideos video'), function(index, $el){
        // console.log(index, $el)
        $el.pause()
      })
    },
    swiperChange(type){
      let currentIndex = this.imgvideosIndex
      if(type == '+') currentIndex++
      if(currentIndex >= this.imgvideos.length - 1) { this.imgvideosIndex = 0 } else { this.imgvideosIndex = currentIndex }
      if(type == '-') currentIndex--
      if(currentIndex < 0) { this.imgvideosIndex = this.imgvideos.length - 1} else { this.imgvideosIndex = currentIndex }
    },
    sort(win, col){
      const planSort = this.sorts[win][col] === 'desc' ? 'asc' : 'desc'
      const keys = Object.keys(this.sorts[win])
      keys.forEach(key => {
        this.sorts[win][key] = ''
      })
      this.sorts[win][col] = planSort
      this[win] = _.orderBy(this[win], [col], [planSort])

      // 强制刷新子组件
      this.hackReset = false
      this.$nextTick(()=>{
          this.hackReset = true
      })
    },

    set(param, data) {
      this[param] = data;
      this.open('win-' + param, this.info[param].w, this.info[param].h, this.info[param].t)
      this.sorts[param] = {}
    },
    clear(param) {
      this[param] = []
      this.close('win-' + param)
    },

    open(el_Id, w, h, offsetTop = 0){   // offsetTop - 高度纠偏
      dataV.Sound.play('打开')
      const $el = $('#' + el_Id)
      // console.log(currentLeft, currentTop, currentWidth, currentHeight, w, h)
      dataV.ModelWindows.close('中间窗口-' + el_Id.replace('win-', ''))
      $el.css({
        display: 'block',
        width: 0, height: 0, 
        left: (this.width / 2) + 'px', 
        top: (this.height / 2 + offsetTop) + 'px' 
      }).animate({
        width: w, height: h, 
        left: ((this.width - w) / 2) + 'px', 
        top: ((this.height - h) / 2 + offsetTop) + 'px' 
      }, this.during)
      this.WindowStatus['is' + el_Id.replace('win-', '') + 'Open'] = true
    },
    close(el_Id){   // offsetTop - 高度纠偏
      this.topAllVideos()
      dataV.Sound.play('关闭')
      const $el = $('#' + el_Id)
      const currentTop = $el.offset().top
      const currentHeight = $el.height()
      const offsetTop = (this.height - currentHeight) / 2 - currentTop

      $el.animate({
        width: 0, height: 0, 
        left: (this.width / 2) + 'px', 
        top: (this.height / 2 + offsetTop) + 'px' 
      }, this.during, () => $el.css({ display: 'none' }))
      this.WindowStatus['is' + el_Id.replace('win-', '') + 'Open'] = false
    },
  },
  watch: {
    "$store.state.webSocketCommand"(newval, olval) {
      if (newval == "0|0|0") {
        dataV.ModelWindows.close("win-sections");
        dataV.ModelWindows.close("win-programs");
        dataV.ModelWindows.close("win-tunnels");
        dataV.ModelWindows.close("win-sevens");
        dataV.ModelWindows.close("win-months");
        dataV.ModelWindows.close("win-imgvideos");
        dataV.ModelWindows.close("win-operation");
      } else if (newval == "0|1|1") {
        dataV.ModelWindows.close("win-sections");
      } else if (newval == "0|1|2") {
        dataV.ModelWindows.close("win-programs");
      } else if (newval == "0|1|3") {
        dataV.ModelWindows.close("win-tunnels");
      } else {
        return;
      }
      console.log("dv-wins监听websocket命令");
      console.log(newval);
      console.log(olval);
    },
  },
  mounted() {
      dataV.ModelWindows.regist('中间窗口-sections', this.WindowStatus, 'issectionsOpen', true, this.close, 'win-sections')
      dataV.ModelWindows.regist('中间窗口-programs', this.WindowStatus, 'isprogramsOpen', true, this.close, 'win-programs')
      dataV.ModelWindows.regist('中间窗口-tunnels', this.WindowStatus, 'istunnelsOpen', true, this.close, 'win-tunnels')
      dataV.ModelWindows.regist('中间窗口-sevens', this.WindowStatus, 'issevensOpen', true, this.close, 'win-sevens')
      dataV.ModelWindows.regist('中间窗口-months', this.WindowStatus, 'ismonthsOpen', true, this.close, 'win-months')
      dataV.ModelWindows.regist('中间窗口-imgvideos', this.WindowStatus, 'isimgvideosOpen', true, this.close, 'win-imgvideos')
      dataV.ModelWindows.regist('中间窗口-operation', this.WindowStatus, 'isoperationOpen', true, this.close, 'win-operation')
  },
}
</script>