<style>
#dv-map {
  opacity: 0;
}

/* 地图部分 */
#dv-map-box {
  position: absolute;
  left: 300px;
  top: -150px;
  width: 100%;
  height: 100%;
  background: url("../../../public/imgs/img/bg/images/img_05.png") -8px 102px no-repeat;
  background-size: 70%;
}
#dv-map #dv-map-box .china {
  position: absolute;
  position: relative;
  left: 80px !important;
  top: 180px;
  width: 100%;
  height: 100%;
  background: url("../../../public/imgs/地图改底色.png") 0 0 no-repeat;
  background-size: 100%;
  transform: rotate3d(-20deg, 0, 0);
}
#dv-map #dv-map-box .maplight {
  position: absolute;
  left: 80px !important;
  top: 180px;
  width: 100%;
  height: 100%;
  background: url("../../../public/imgs/img/bg/images/加宽.png") 0 0 no-repeat;
  background-size: 100%;
  transform: rotate3d(-20deg, 0, 0);
  z-index: 2;
}
/* #dv-map #dv-map-box .maplight1{ position: absolute;  left: 80px !important; top: 190px; width: 100%; height: 100%; background: url('../../../public/imgs/img/bg/images/改.png') 0 0 no-repeat; background-size: 100%;transform:rotate3d(-20deg,0,0);} */
#dv-map #dv-map-box .water {
  position: absolute;
  left: 50px !important;
  top: 260px;
  width: 300px;
}
#dv-map #dv-map-box .water img {
  width: 300px;
  opacity: 0.6;
}

#dv-map #dv-map-box .star1 {
  position: absolute;
  left: 635px !important;
  top: 574px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star1 img {
  width: 100px;
}
#dv-map #dv-map-box .star2 {
  position: absolute;
  left: 645px !important;
  top: 348px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star2 img {
  width: 100px;
}
#dv-map #dv-map-box .star3 {
  position: absolute;
  left: 605px !important;
  top: 368px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star3 img {
  width: 100px;
}
#dv-map #dv-map-box .star4 {
  position: absolute;
  left: 655px !important;
  top: 332px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star4 img {
  width: 100px;
}
#dv-map #dv-map-box .star5 {
  position: absolute;
  left: 578px !important;
  top: 370px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star5 img {
  width: 100px;
}
#dv-map #dv-map-box .star6 {
  position: absolute;
  left: 435px !important;
  top: 440px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star6 img {
  width: 100px;
}
#dv-map #dv-map-box .star7 {
  position: absolute;
  left: 140px !important;
  top: 470px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star7 img {
  width: 100px;
}
#dv-map #dv-map-box .star8 {
  position: absolute;
  left: 295px !important;
  top: 365px;
  width: 100px;
  z-index: 3;
}
#dv-map #dv-map-box .star8 img {
  width: 100px;
}

#dv-map-box1 {
  position: absolute;
  left: 0;
  top: -50px;
  width: 100%;
  height: 100%;
  background: url("../../../public/imgs/img/bg/images/img_01.png") -8px 102px no-repeat;
  background-size: 100%;
}


#dv-map #dv-map-box1 .china_soundsea {
  position: absolute;
  right: 660px;
  top: 760px;
  width: 60px;
}
#dv-map #dv-map-box .programSign {
  position: absolute;
}
#dv-map #dv-map-box .programSign .sign {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  cursor: pointer;
}
#dv-map #dv-map-box .programSign .sign2 {
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: 0;
  cursor: pointer;
}
#dv-map #dv-map-box .programSign .flag {
  position: absolute;
  left: 11px;
  top: 11px;
  width: 400px;
  pointer-events: none;
}
#dv-map #dv-map-box .programSign .sign_animate {
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: 2;
  pointer-events: none;
}
#dv-map #dv-map-box .programSign.hide {
  display: none;
}
#dv-map #dv-map-box .yun-item1 {
  position: absolute;
  left: 120px;
  top: 140px;
  width: 100%;
  height: 200px;
  background: url("../../../public/imgs/img/云1.png") 0 0 no-repeat;
  background-size: 50%;
}
#dv-map #dv-map-box .yun-item2 {
  position: absolute;
  left: 300px;
  top: 300px;
  width: 100%;
  height: 200px;
  background: url("../../../public/imgs/img/云2.png") 0 0 no-repeat;
  background-size: 50%;
}
#dv-map #dv-map-box .yun-item3 {
  position: absolute;
  left: -600px;
  top: 200px;
  width: 100%;
  height: 200px;
  background: url("../../../public/imgs/img/云2.png") 0 0 no-repeat;
  background-size: 50%;
}
#dv-map #dv-map-box .yun-item4 {
  position: absolute;
  left: -300px;
  top: 100px;
  width: 100%;
  height: 200px;
  background: url("../../../public/imgs/img/云1.png") 0 0 no-repeat;
  background-size: 50%;
}

/* VR窗口部分 */
#dv-map #webVRbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
#dv-map #webVRbox .time {
  position: absolute;
  right: 45px;
  top: 50px;
  width: 400px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: rgb(0, 112, 216);
}
#dv-map #webVRbox .vrbrowser {
  position: absolute;
  left: 500px;
  top: 100px;
  width: 1310px;
  height: 550px;
  background-color: #000000;
  border: 2px solid #0049ff;
}
#dv-map #webVRbox .vrbrowser iframe {
  border: 0;
}

#dv-map #webVRbox .btns {
  position: absolute;
  left: 1825px;
  top: 100px;
}

#dv-map #webVRbox .bg_btn03 {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn04 {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn06 {
  position: absolute;
  left: 0;
  top: 60px;
  cursor: pointer;
}

#dv-map #webVRbox .bg_btn25 {
  position: absolute;
  left: 0;
  top: 120px;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn26 {
  position: absolute;
  left: 0;
  top: 180px;
  cursor: pointer;
}

#dv-map #webVRbox .bg_btn11 {
  position: absolute;
  left: 0;
  top: 324px;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn12 {
  position: absolute;
  left: 0;
  top: 384px;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn14 {
  position: absolute;
  left: 0;
  top: 444px;
  cursor: pointer;
}
#dv-map #webVRbox .bg_btn13 {
  position: absolute;
  left: 0;
  top: 504px;
  cursor: pointer;
}

/* 作废的3个按钮：方向、旋转、VR模式 */
#dv-map #webVRbox .bg_btn01 {
  position: absolute;
  left: 500px;
  top: 450px;
}
#dv-map #webVRbox .bg_btn02 {
  position: absolute;
  left: 500px;
  top: 450px;
}
#dv-map #webVRbox .bg_btn05 {
  position: absolute;
  left: 500px;
  top: 330px;
  cursor: pointer;
}

#dv-map .CurrentLightBox {
  position: absolute;
  left: 1190px;
  top: 60px;
  width: 1360px;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
}

#dv-map .ReportViewer {
  position: absolute;
  left: 1550px;
  top: 105px;
  width: 735px;
  height: 935px;
  overflow: hidden;
  display: none;
  z-index: 10000000;
}
#dv-map .ReportViewer .report_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 735px;
  height: 935px;
}
#dv-map .ReportViewer .report_pdf {
  position: absolute;
  left: 3px;
  top: 60px;
  width: 725px;
  height: 800px;
}
#dv-map .ReportViewer .report_btn {
  position: absolute;
  left: 700px;
  top: 28px;
  width: 26px;
  cursor: pointer;
}
#dv-map .ReportViewer .report_title {
  position: absolute;
  left: 10px;
  top: 30px;
  width: 635px;
  font-size: 16px;
  font-weight: bold;
}
.el-select > .el-input input {
  background-color: #061963;
  color: white;
  border: 1px #0150dd solid;
}
.el-select > .el-input input:hover {
  border: 1px #0150dd solid;
}
.el-select-dropdown {
  background-color: #061963 !important;
  border: 1px #0150dd solid !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #0150dd !important;
}
.el-select-dropdown__item {
  color: white !important;
}
#webVRbox .adminhref{
  width: 200px;
  height: 30px;
  position: absolute;
  z-index: 50;
  right:45px;
  top:1040px;
  line-height: 30px;
  
}
#webVRbox .adminhref a{text-decoration: none;color: #6a6b7e; float: right;font-size: 14px;}

</style>

<template>
  <div id="dv-map">
    <div v-if="currentPageLevel != 3" id="dv-map-box">
      <div class="yun-item1"></div>
      <div class="yun-item2"></div>
      <div class="yun-item3"></div>
      <div class="yun-item4"></div>
      <div class="water">
        <img src="../../../public/imgs/img/bg/images/水波纹.png" alt="" />
      </div>
      <div class="star1">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star2">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star3">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star4">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star5">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star6">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star7">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div class="star8">
        <img src="../../../public/imgs/img/bg/images/星星.png" alt="" />
      </div>
      <div
        class="maplight"
        :style="
          'left: ' +
          mapOffsetX +
          'px; background-size: ' +
          1440 * xyScale +
          'px'
        "
      ></div>
      <!-- <div v-show="currentPageLevel != 3" class="maplight1" :style="'left: ' + mapOffsetX + 'px; background-size: ' + (1440 * xyScale) + 'px'"></div> -->
      <!--dv-img class="china" src="imgs/img/map/map.json" type="svg"></dv-img-->
      <!-- <dv-img class="china" :src="'imgs/' + currentArea + '地图.png'" @click="tellme"></dv-img> -->
      <div
        class="china"
        :style="
          'left: ' +
          mapOffsetX +
          'px; background-size: ' +
          1440 * xyScale +
          'px'
        "
      >
      <!-- 地图隧道 -->
      <template v-if="refshmap" >
        <div v-for="(item,index) in TunnelInMapList" :key="index" :id="item.Id"  @mouseover="showIcon(item.Id,index)" @mouseleave="hideIcon(item.Id,index)"
        @click="selectTunnel(3,item.Id,currentPageLevel)"
        :style="'position:absolute;width: 80px;height: 80px;cursor: pointer;z-index: 3;top:'+item.ScreenMapY+'px;left:'+item.ScreenMapX+'px'"
        >
          <dv-img
              style="width: 80px;height: 80px;line-height: 200px;text-align: center;z-index: 3;" src="imgs/img/bg/images/小图标动.png"
            ></dv-img>
            <dv-img
            :class="'item'+index"
              style="position: absolute;left: -70px;top: -70px;width: 300px;pointer-events: none;display:none" :src="item.ShowScreenPic ? ImgBaseUrl+item.ShowScreenPic :'imgs/img/bg/images/隧道.png'"
            ></dv-img>
        </div>
      </template>
      
      </div>

     
    </div>
    <div v-if="false" id="dv-map-box1">
      <div
        class="china1"
        :style="
          'left: ' +
          mapOffsetX +
          'px; background-size: ' +
          1440 * xyScale +
          'px'
        "
      ></div>

      <div
        class="programSign"
        v-for="(item, index) in ProjectList"
        :key="index"
        :style="
          'left: ' +
          (mapOffsetX + item.ScreenMapX * xyScale) +
          'px; top: ' +
          item.ScreenMapY * xyScale +
          'px'
        "
      >
        <dv-img
          class="sign2"
          src="imgs/p20/1594027483255-8d35851a-d2d4-4d9d-bb34-319fb136ecb3.png"
          :scale="
            'left: -' +
            40 * xyScale +
            'px; top: -' +
            40 * xyScale +
            'px; width: ' +
            130 * xyScale +
            'px'
          "
          :title="item.Name"
          :data-id="item.Id"
          @click="selectProgram"
        ></dv-img>
        <dv-img
          v-if="currentPageLevel == 2 && item.Id == currentProgram"
          class="sign_animate"
          :scale="
            'left: -' +
            40 * xyScale +
            'px; top: -' +
            40 * xyScale +
            'px; width: ' +
            130 * xyScale +
            'px'
          "
          src="imgs/p20/1594027483255-8d35851a-d2d4-4d9d-bb34-319fb136ecb3.png"
          :data-id="item.Id"
          @click="selectProgram"
        ></dv-img>
        <dv-img
          v-if="currentPageLevel == 2 && item.Id == currentProgram"
          class="flag"
          :scale="
            'left: ' +
            11 * xyScale +
            'px; top: ' +
            11 * xyScale +
            'px; width: ' +
            400 * xyScale +
            'px'
          "
          :src="ImgBaseUrl + item.ShowScreenPic"
          :data-id="item.Id"
          @click="selectProgram"
        ></dv-img>
      </div>
      <dv-img class="china_soundsea" src="imgs/比例尺.png"></dv-img>
    </div>
    <div v-if="currentPageLevel == 3" id="webVRbox">
      <i class="time">
        <el-select
          style="width: 388px"
          v-model="selectedTestingOrg"
          class="TestingOrgSelector"
          popper-class="test"
        >
          <el-option
            v-for="(item, index) in testingOrgList"
            :key="index + '_' + item.Id"
            :value="item.Id"
            :label="item.Name"
          ></el-option>
        </el-select>
      </i>

      <div class="vrbrowser">
        <iframe border="0" width="100%" height="100%" :src="vrURL"></iframe>
      </div>
      <div class="btns">
        <el-tooltip content="衬砌厚度不足" placement="left-start">
          <dv-img
            class="bg_btn11"
            :src="'imgs/p4/95d3ab63-9402-43c5-91be-18a9606a96bb.png'"
            @click="toggleDefect('衬砌厚度不足')"
          ></dv-img>
        </el-tooltip>
        <el-tooltip content="钢筋缺失问题" placement="left-start">
          <dv-img
            class="bg_btn12"
            :src="'imgs/p4/37cbb04f-61d9-4de9-a3ec-635c055d8153.png'"
            @click="toggleDefect('钢筋缺失问题')"
          ></dv-img>
        </el-tooltip>
        <el-tooltip content="其他问题" placement="left-start">
          <dv-img
            class="bg_btn13"
            :src="'imgs/p4/89330cac-5598-4f6a-a953-492dac43bb58.png'"
            @click="toggleDefect('其他问题')"
          ></dv-img>
        </el-tooltip>
        <el-tooltip content="防裂钢筋网问题" placement="left-start">
          <dv-img
            class="bg_btn14"
            :src="'imgs/p4/bc4487d1-ddc3-420c-847b-3b0dd40ba078.png'"
            @click="toggleDefect('防裂钢筋网问题')"
          ></dv-img>
        </el-tooltip>
        <el-tooltip
          v-show="toplist"
          content="返回上一级"
          placement="left-start"
        >
          <dv-img
            class="bg_btn03"
            :src="'imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png'"
            @click="goBack"
          ></dv-img>
        </el-tooltip>
        <el-tooltip
          class="tooltipback"
          content="回到起点"
          placement="left-start"
        >
          <dv-img
            class="bg_btn04"
            :src="'imgs/p4/83469246-de44-4994-ac6e-5c7a470fea59.png'"
            @click="vrResetCamare"
          ></dv-img>
        </el-tooltip>
        <el-tooltip
          class="tooltipback"
          content="按键操作"
          placement="left-start"
        >
          <dv-img
            class="bg_btn06"
            :src="'imgs/p4/bca4d53f0d60ebb32c5b98086a22ded.png'"
            @click="help"
          ></dv-img>
        </el-tooltip>
        <dv-img
          class="bg_btn05"
          :src="'imgs/p4/1f3c0fda-4bea-4fb8-8b9e-0b185012736b.png'"
          @click="toggleVR"
        ></dv-img>

        <dv-img
          v-if="hasReport"
          class="bg_btn25"
          :src="'imgs/p4/1ceca2bb-6776-4289-b750-d08ff9525a42.png'"
          @click="viewReport('report')"
        ></dv-img>
        <dv-img
          v-if="hasRepairReport"
          class="bg_btn26"
          :src="'imgs/p4/1f3c0fda-4bea-4fb8-8b9e-0b185012736b.png'"
          @click="viewReport('repair')"
        ></dv-img>

        <dv-img class="bg_btn01" :src="'imgs/p4/' + goImg + '.png'"></dv-img>
        <dv-img class="bg_btn02" :src="'imgs/p4/' + turnImg + '.png'"></dv-img>
      </div>

      <div class="ReportViewer">
        <dv-img class="report_bg" src="imgs/honours/证书背景.png"></dv-img>
        <div class="report_title">查看报告</div>
        <dv-img
          class="report_btn"
          src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png"
          @click="closeReport"
        ></dv-img>
        <iframe class="report_pdf" border="0" src=""></iframe>
      </div>

      <div id="testDiv"></div>
      <div class="adminhref"><a :href="adminlj+token" target="_blank">后台管理登录</a></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import dataV from "../../bin/dataV";
import dvImg from "./dv-img";
// import * as _ from 'lodash'

export default {
  data: function () {
    return {
      token:window.ApiData.token,
      toplist: false,
      Id: "CenterMapComponent",
      testingOrgList: [],
      selectedTestingOrg: "",
      adminlj : dataV.Config.adminhref,
      currentTime:
        dataV.DateTime.format("YYYY-mm-dd HH:MM:SS") +
        "　" +
        dataV.DateTime.getWeekCH(),
      ImgBaseUrl: dataV.Config.baseUrl.img,

      ProjectList: window.ApiData.screen.ProjectList,
      TunnelInMapList: window.ApiData.screen.TunnelInMapList,
      lastId: dataV.Page.LastId,
      currentPageLevel: dataV.Page.CurrentLevel, // 大屏当前页面层级，1-全国、2-项目部
      currentArea: "全国区域",
      currentTunnel: dataV.Config.initPage.tunnel,
      currentDefect: dataV.Config.initPage.defect,
      fanhuiid:
        dataV.Page.CurrentLevel == 0
          ? ""
          : dataV.Page.CurrentLevel == 1
          ? dataV.Page.CurrentSectionId
          : dataV.Page.CurrentLevel == 2
          ? dataV.Page.CurrentId
          : dataV.Page.CurrentLevel == 3
          ? dataV.Page.CurrentTunnelId
          : "",
      
      currentProgram: dataV.Page.CurrentId,
      lastLevels: dataV.Page.LastLevel,
      vrURL: dataV.Config.vr.url,
      
      goImg: "1a5858c1-80c8-4cfc-886b-72cca18c37fa",
      turnImg: "8cf42615-2c94-4ff4-a522-844a86f766fa",

      hasReport: false,
      hasRepairReport: false,
      hasRepairCase: false,
      refshmap:false,

      WindowStatus: {
        isReportWindowOpen: false,
      },

      mapOffsetX: 390,
      xyScale: 0.76,

  
    };
    
  },
  props: [],
  created() {
    window[this.Id] = this;
    window.dataV.MapComponent = this;
    console.log(this.tunnelIds)
    
  },
  computed: {},
  methods: {
    help() {
      window.CenterWindowsComponent.set("operation");
    },
    showIcon(index,a) {
     
      $('#'+index+' img:nth-child(1)').css({display:'none'})
      $('#'+index+' .item'+a).css({display:'block'})
    },
    hideIcon(index,a) {
    
      $('#'+index+' img:nth-child(1)').css({display:'block'})
      $('#'+index+' .item'+a).css({display:'none'})
    },
    tellme() {
      console.log(event.offsetX, event.offsetY);
    },

    sync() {
      // $('#dv-map').css('opacity', 0)
      this.currentPageLevel = dataV.Page.CurrentLevel;
      this.currentProgram = dataV.Page.CurrentId;
      this.lastLevels = dataV.Page.LastLevel;
      this.fanhuiid =
        dataV.Page.CurrentLevel == 0
          ? ""
          : dataV.Page.CurrentLevel == 1
          ? ''
          : dataV.Page.CurrentLevel == 2
          ? dataV.Page.CurrentSectionId
          : dataV.Page.CurrentLevel == 3
          ? dataV.Page.CurrentId 
          : "";

      this.testingOrgList = [{ Id: "", Name: "全部检测机构" }];
      window.ApiData.userRoot.TestingOrgList.forEach((testingOrg) => {
        this.testingOrgList.push(testingOrg);
      });
      // this.selectedTestingOrg = dataV.Page.testingOrgId
      // console.log('testingOrgId'+dataV.Page.testingOrgId)
    },

    viewReport(type) {
      if (dataV.WebVR) {
        if (dataV.WebVR.Report && type == "report") {
          dataV.ModelWindows.close("缺陷报告");
          // this.sound4open.run(true);
          this.WindowStatus.isReportWindowOpen = true;
          $(".ReportViewer .report_pdf").attr(
            "src",
            dataV.Config.baseUrl.img + dataV.WebVR.Report.url
          );
          $(".ReportViewer")
            .css({
              width: 0,
              height: 0,
              left: "910px",
              top: "565px",
            })
            .show()
            .animate(
              {
                width: "735px",
                height: "935px",
                left: "610px",
                top: "95px",
              },
              500
            );
        } else if (dataV.WebVR.RepairReport && type == "repair") {
          dataV.ModelWindows.close("缺陷报告");
          // this.sound4open.run(true);
          this.WindowStatus.isReportWindowOpen = true;
          $(".ReportViewer .report_pdf").attr(
            "src",
            dataV.Config.baseUrl.img + dataV.WebVR.RepairReport.url
          );
          $(".ReportViewer")
            .css({
              width: 0,
              height: 0,
              left: "910px",
              top: "565px",
            })
            .show()
            .animate(
              {
                width: "735px",
                height: "935px",
                left: "610px",
                top: "95px",
              },
              500
            );
        } else if (dataV.WebVR.RepairCase && type == "repairCase") {
          dataV.ModelWindows.close("缺陷报告");
          // this.sound4open.run(true);
          this.WindowStatus.isReportWindowOpen = true;
          $(".ReportViewer .report_pdf").attr(
            "src",
            dataV.Config.baseUrl.img + dataV.WebVR.RepairCase.url
          );
          $(".ReportViewer")
            .css({
              width: 0,
              height: 0,
              left: "910px",
              top: "565px",
            })
            .show()
            .animate(
              {
                width: "735px",
                height: "935px",
                left: "610px",
                top: "95px",
              },
              500
            );
        }
      }
    },
    closeReport() {
      // this.sound4close.run(true);
      this.WindowStatus.isReportWindowOpen = false;
      $(".ReportViewer").animate(
        {
          width: 0,
          height: 0,
          left: "910px",
          top: "565px",
        },
        500,
        function () {
          $(".ReportViewer").hide();
        }
      );
    },

    hideVRBTN() {
      $("#webVRbox .bg_btn05").hide();
    },

    goBack() {
      if (this.lastLevels>=0) {
        // console.log(1111,dataV.Page.CurrentSectionId,dataV.Page.CurrentId)
        dataV.Page.Toggle(this, this.lastLevels, dataV.Page.CurrentId?dataV.Page.CurrentId:dataV.Page.CurrentSectionId);
        
      } else {
       
        dataV.Page.Toggle(this, 2, dataV.Page.CurrentId);
      }
    },

    vrResetCamare() {
      if (dataV.WebVR) {
        dataV.WebVR.resetCamare();
      }
    },

    toggleVR() {
      if (dataV.WebVR) {
        dataV.WebVR.toggleWebVR();
      }
    },

    toggleDefect(defect) {
      window.BottomTunnelComponent.toggleDefect({ target: { title: defect } });
    },

    toggleBtnImg(command) {
      switch (command) {
        case "go-left":
          this.goImg = "3bc7b399-f7a6-4ad6-aa2a-8e3a321542f1";
          break;
        case "go-right":
          this.goImg = "a66861e9-a3dc-459d-bba6-52574bc95377";
          break;
        case "go-front":
          this.goImg = "adae866e-1980-41c2-b98d-13a55d4720a6";
          break;
        case "go-back":
          this.goImg = "d8fac20a-5050-4863-b3d1-56ea284504fa";
          break;
        case "go-default":
          this.goImg = "1a5858c1-80c8-4cfc-886b-72cca18c37fa";
          break;
        case "turn-left":
          this.turnImg = "a032f1e1-9b89-4fc7-a96d-e495a0936ace";
          break;
        case "turn-right":
          this.turnImg = "f3f30a7b-87a1-47da-9c5f-2f1f08805219";
          break;
        case "turn-up":
          this.turnImg = "02fdf88c-a08c-4886-8094-f47550aabbab";
          break;
        case "turn-down":
          this.turnImg = "f99c0a63-abab-4ff9-83a2-6f479f13a315";
          break;
        case "turn-default":
          this.turnImg = "8cf42615-2c94-4ff4-a522-844a86f766fa";
          break;
        default:
          this.goImg = "1a5858c1-80c8-4cfc-886b-72cca18c37fa";
          this.turnImg = "8cf42615-2c94-4ff4-a522-844a86f766fa";
      }
    },

    selectProgram() {
      // 跟随按钮，切换地图右侧的项目文字列表
      // var dvRightMenu = this.$parent.$children.filter((item) => { return item.$el.id == 'dv-right-menu' })[0];
      dataV.Page.Toggle(this, 2, $(event.target).data("id"));
    },
    selectTunnel(level, Id, lastleve) {
      // console.log(1302, lastleve);
     
        dataV.Page.Toggle(this, level, Id, lastleve);
   
      
    },
  },
  watch: {
    selectedTestingOrg() {
      dataV.Page.testingOrgId = this.selectedTestingOrg;
      dataV.Page.Toggle(this, 3, dataV.Page.CurrentSectionId);

         this.refshmap =false
      let that = this
      setTimeout(function(){
        that.refshmap =true
      },200)
    },
    currentTunnel() {
      this.vrURL += "?now=" + new Date();
    },
    currentPageLevel() {
    
      
      // 判断是否显示返回
      if (window.ApiData.userRoot.PageRoot - 1 < this.currentPageLevel) {
        this.toplist = true;
        $(document).ready(function () {
          $("#dv-map #webVRbox .bg_btn04").css({ top: "60px" });
          $("#dv-map #webVRbox .bg_btn06").css({ top: "120px" });
        });
      } else {
        this.toplist = false;
      }

        this.refshmap =false
      let that = this
      setTimeout(function(){
        that.refshmap =true
      },200)
    },
    // TunnelInMapList() {
    //   for(let i =0 ;i<this.tunnelIds.length;i++){
    //   $('#'+this.tunnelIds[i].Id).css({display:'none'})
    // }
      
    //    let that = this
    //    for(let i = 0; i<that.TunnelInMapList.length;i++){
    //      let index = _.findIndex(that.tunnelIds, function(o) { return o.Id == that.TunnelInMapList[i].Id; })
    //      if(index >= 0){
    //       //  console.log(1456,that.tunnelIds[i])
    //        $(document).ready(function(){
    //          $('#'+that.TunnelInMapList[i].Id).css({display:'block',top:that.TunnelInMapList[i].ScreenMapY,left:that.TunnelInMapList[i].ScreenMapX})
    //        }) 
    //      }
    //    }
    // },
    "$store.state.webSocketCommand"(newval, olval) {
      if (newval == "0|0|3") {
        this.goBack()
      } else {
        return;
      }
      console.log("dv-map监听websocket命令");
      console.log(newval);
      console.log(olval);
    },
  },
  mounted: function () {
    // 人员信息配乐
    // this.sound4toggle = dataV.TaskSystem.sound.copy('sounds/10808.wav');
    // this.sound4menu = dataV.TaskSystem.sound.copy('sounds/696.wav');
    // this.sound4menuhover = dataV.TaskSystem.sound.copy('sounds/02.mp3');
    // this.sound4open = dataV.TaskSystem.sound.copy('sounds/9675.wav');
    // this.sound4close = dataV.TaskSystem.sound.copy('sounds/9676.wav');
    // this.sound4fly = dataV.TaskSystem.sound.copy('sounds/9073.wav');
      
    // 注册弹出窗口
    dataV.ModelWindows.regist(
      "缺陷报告",
      this.WindowStatus,
      "isReportWindowOpen",
      true,
      this.closeReport
    );
    // let that = this
    //   for(let i =0 ;i<that.TunnelInMapList.length;i++){
    //     // var cont =  _.findIndex(that.tunnelIds, function(o) { return o.Id == that.TunnelInMapList[i].Id; });
    //     //   console.log(1497,cont)
    //     // if(cont>=0){
    //     //   $('#'+that.TunnelInMapList[i].Id).css({display:'block',top:that.TunnelInMapList[i].ScreenMapY,left:that.TunnelInMapList[i].ScreenMapX})
    //     // }
    //     $('.china').append()

    // }

    // function getBase64Image(img) {
    // 	var canvas = document.createElement("canvas");
    // 	canvas.width = img.width;
    // 	canvas.height = img.height;
    // 	var ctx = canvas.getContext("2d");
    // 	ctx.drawImage(img, 0, 0, img.width, img.height);
    // 	var dataURL = canvas.toDataURL("image/png");
    // 		return dataURL;
    // 	//return dataURL.replace("data:image/png;base64,", "");
    // }

    // var img = new Image();
    // // img.setAttribute('crossOrigin', 'anonymous');
    // img.onload =function() {
    // 	$('#testDiv').append(img)
    // 		var data = (function getBase64Image(_img) {
    // 		var canvas = document.createElement("canvas");
    // 		canvas.width = _img.width;
    // 		canvas.height = _img.height;
    // 		var ctx = canvas.getContext("2d");
    // 		ctx.drawImage(_img, 0, 0, _img.width, _img.height);
    // 		var dataURL = canvas.toDataURL("image/png");
    // 			return dataURL;
    // 		//return dataURL.replace("data:image/png;base64,", "");
    // 	})(img);
    // 	console.log('.....................', data);
    // }
    // // img.src = 'https://cr12hh.sxaliyun.com/Resource/TestFaultShowPic/8e905bc5-586f-4251-9e02-33c816be6507/79a460b0-1e64-47bd-a706-f88fa2777d20.png';
    // img.src = 'imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png';
       this.refshmap =false
      let that = this
      setTimeout(function(){
        that.refshmap =true
      },200)
    
  },

  components: {
    "dv-img": dvImg,
  },
};
</script>
