//引入安装的signalr包
import * as signalR from '@aspnet/signalr'
import Config from '../config/Config'

const signal = new signalR.HubConnectionBuilder()
  .withUrl(Config.baseUrl.wsAddr)
  .build()

/* const signalr = function () {
  var hub
  if (hub === undefined) {
    hub = signal
  }
  return hub
} */
//  自动重连
/* async function start () {
  try {
    await signal.start()
    console.log('connected')
  } catch (err) {
    console.log(err)
    setTimeout(() => start(), 5000)
  }
}

signal.onclose(async () => {
  await start()
}) */

signal.onmessage = function (messageType) {
  signal.on(messageType, (userName, userMessage) => {
    //可以做相关业务逻辑
    console.log("000收到websocket命令：" + userName + " - " + userMessage);
    return (userName, userMessage);
  });
}

//将创建的signal赋值给Vue实例
export default {
  //install方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
  install: function (Vue) {
    Vue.prototype.signalr = signal
  }
}